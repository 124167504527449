import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import {
  Box,
  IconButton,
  Link,
  Grid,
  makeStyles,
  Button,
  TextField,
  Typography as MaterilTypography,
} from "@material-ui/core";
import Icon from "@mdi/react";
import Footer from "./Footer";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from "@mdi/js";

// styles

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useWeb3React } from "@web3-react/core";

// pages
import Staking from "../../pages/Staking/Staking";
import { useLayoutState } from "../../context/LayoutContext";
import { ACTIVE_NETWORK, NetworkContextName } from "../../constants";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: "24px 150px",
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
    paddingTop: "80px",
    "@media (max-width:1366px)": {
      padding: "24px 100px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "24px 24px",
      paddingTop: "80px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "24px 15px",
    },
  },
  contentShift: {
    width: `calc(100vw - 240px)`,
    position: "absolute",
    right: "0",
    // overflowX: "hidden",
    // transition: theme.transitions.create(["width", "margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
    [theme.breakpoints.down("sm")]: {
      width: `100%`,
    },
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    "&:not(:first-child)": {
      paddingLeft: 15,
    },
  },

  buttonright: {
    background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
    fontSize: "14px ",
    border: "1px solid transparent",
    fontWeight: 600,
    height: "44px ",
    color: "#FFFFFF",
    minWidth: "135px ",
    borderRadius: "5px",
    boxShadow: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      color: "#fff",
      transition: "0.5s",
      boxSizing: " border-box",
      border: " 2px solid transparent",
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      backgroundImage:
        "linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)",
    },
  },
  shade: {
    position: "fixed",
    left: "auto",
    top: "auto",
    right: "10%",
    bottom: "10%",
    transform: "rotate(45deg)",
    zIndex: " -1",
    width: " 300px",
    height: "300px",
    borderRadius: " 1000px",
    backgroundImage:
      "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(42 123 135) 0%, rgb(39 121 124) 95.78%)",
    opacity: "0.55",
    filter: "blur(100px)",
  },
  shade2: {
    position: "fixed",
    left: "auto",
    top: "10%",
    right: "10%",
    bottom: "auto",
    zIndex: " -1",
    width: " 300px",
    height: "300px",
    borderRadius: " 1000px",
    backgroundImage:
      "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(18 52 140 / 58%) 0%, rgb(18 52 140 / 55%) 95.78%)",
    opacity: "0.55",
    filter: "blur(100px)",
  },
  shade3: {
    position: "fixed",
    left: "10%",
    top: "21%",
    right: "auto",
    bottom: "auto",
    transform: "rotate(45deg)",
    zIndex: " 1",
    width: " 250px",
    height: "250px",
    borderRadius: " 1000px",
    backgroundImage:
      "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(42 123 135) 0%, rgb(39 121 124) 95.78%)",
    opacity: "0.55",
    filter: "blur(100px)",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mainBoxBody: {
    minHeight: "calc(100vh - 180px)",
  },
}));
function Layout(props) {
  const classes = useStyles();
  // global
  var layoutState = useLayoutState();
  const { account, active, library, chainId } = useWeb3React();
  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <Box className={classes.mainBoxBody}>
            <div className={classes.shade}></div>
            <div className={classes.shade2}></div>
            <div className={classes.shade3}></div>
            {/* <div className={classes.fakeToolbar} /> */}
            <Switch>
              <Route path="/app/dashboard" component={Staking} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
            </Switch>
          </Box>

          <Footer />
        </div>
      </>
      {account && (
        <Dialog
          open={account && chainId !== ACTIVE_NETWORK}
          minWidth="md"
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Netwok Change Error"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box ml={3} pb={3}>
                <MaterilTypography style={{ color: "black" }}>
                  Please switch to {NetworkContextName} network
                </MaterilTypography>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default withRouter(Layout);
