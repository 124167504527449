import React from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  Container,
  TextField,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  textButton: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "2px",
      fontSize: "13px",
    },

    "& button": {
      borderRadius: "4px",
      color: "#fff",
      padding: "4px 14px",
      fontSize: "12px",
      fontWeight: 700,
      border: " 2px solid transparent",
      minWidth: "53px",
      backgroundColor: "#706e6e",
      marginRight: "5px",
      "&:hover": {
        backgroundColor: "#706e6e",
      },
    },
  },
  buttonright: {
    background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
    fontSize: "12px ",
    border: "1px solid transparent",
    fontWeight: 600,
    padding: " 9.5px 14px",
    minWidth: "150px",
    color: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: "none ",
    cursor: "pointer",
    border: " 2px solid transparent",
    [theme.breakpoints.down("xs")]: {
      height: "30 ",
      minWidth: "50px ",
    },
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      color: "#fff",
      transition: "0.5s",
      boxSizing: " border-box",
      border: " 2px solid transparent",
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      backgroundImage:
        "linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)",
    },
    "&:disabled": {
      boxShadow: "none",
      background: "rgb(163 200 186 / 35%)",
      color: "#313030",
    },
  },
  refftext: {
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "23px",
    color: "#fff",
    marginBottom: "6px",
  },
  daddytext: {
    display: "flex",
    justifyContent: "space-between",
    "& span": {
      color: "#e4eded",
      "& strong": {
        color: "#fff",
      },
    },
  },
}));
export default function Stake(props) {
  const classes = useStyles();
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box className={classes.daddytext}>
            <Typography
              variant="body2"
              align="left"
              className={classes.refftext}
            >
              Amount to Reward Claim
            </Typography>
            <span>
              {" "}
              Daddy Balance <strong>17539</strong>
            </span>
          </Box>
          <TextField
            variant="outlined"
            placeholder="0.00"
            size="large"
            fullWidth
            className={classes.textButton}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button>MAX</Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="left" className={classes.refftext}>
            Enter My Referral Code
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Enter My Referral code"
            size="large"
            fullWidth
            className={classes.textButton}
            type="text"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.buttonright}
            variant="contained"
            size="large"
            style={{ color: "#fff !important" }}
          >
            Reward Claim
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
