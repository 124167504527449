import React from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  Container,
  makeStyles,
} from "@material-ui/core";
import RevenueCard from "../RevenueCard";
const useStyles = makeStyles((theme) => ({}));
const RevenueData = [
  {
    number: "$474.67",
    heding: "24hrs Stake Reward",
  },
  {
    number: "$474.64",
    heding: "Claimed Stake Rewards",
  },
  {
    number: "$474.26",
    heding: "Total Stake Reward",
  },
  {
    number: "$474.00",
    heding: "Claimable Stake Rewards",
  },
];
export default function Stake(props) {
  const classes = useStyles();
  return (
    <Box className={classes.textbox}>
      <Grid container spacing={2} alignItems="center">
        {RevenueData.map((data, i) => {
          return (
            <Grid item xs={6} sm={4} md={6} lg={4} key={i}>
              <RevenueCard data={data} type="card" index={i} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
