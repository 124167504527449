import React from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  Container,
  makeStyles,
} from "@material-ui/core";
import RevenueCard from "./RevenueCard";
const useStyles = makeStyles((theme) => ({}));
const RevenueData = [
  {
    number: "$474.00",
    heding: "Total Revenue",
  },
  {
    number: "$474.00",
    heding: "24hrs Revenue",
  },
  {
    number: "$474.00",
    heding: "Total Stake Volume",
  },
  {
    number: "474",
    heding: "Total Community",
  },
  {
    number: "$474.00",
    heding: "Total Claimed Rewards",
  },
  {
    number: "$474.00",
    heding: "Total Commission",
  },
];
export default function Stake(props) {
  const classes = useStyles();
  return (
    <Box className={classes.textbox}>
      <Grid container spacing={2} alignItems="center">
        {RevenueData.map((data, i) => {
          return (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              key={i}
              className="walletSet"
            >
              <RevenueCard data={data} type="card" index={i} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
