import React from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  Container,
  TextField,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

const rows = [
  {
    srNumber: "1",
    date: "12/02/2022 12:00:00 AM",
    amount: "2000.00",
    rewards: "rewards ",
  },
  {
    srNumber: "2",
    date: "12/02/2022 12:00:00 AM",
    amount: "2000.00",
    stake: "Staked",
  },
  {
    srNumber: "3",
    date: "12/02/2022 12:00:00 AM",
    amount: "2000.00",
    withdrawn: "Withdrawn",
  },
  {
    srNumber: "4",
    date: "12/02/2022 12:00:00 AM",
    amount: "2000.00",
    added: "Added",
  },
  {
    srNumber: "5",
    date: "12/02/2022 12:00:00 AM",
    amount: "2000.00",
    unstaked: "Unstaked",
  },
  {
    srNumber: "3",
    date: "12/02/2022 12:00:00 AM",
    amount: "2000.00",
    unstaked: "Unstaked",
  },
  {
    srNumber: "4",
    date: "12/02/2022 12:00:00 AM",
    amount: "2000.00",
    added: "Added",
  },
];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    "& select": {
      height: "30px",
      minWidth: "100px",
      borderRadius: "2px",
      border: "1px solid #444A5F",
      background:
        "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
      color: "#fff",
      "&:focus": {
        outline: "none",
      },
    },
    "& button": {
      backgroundColor: "#ccc",
      color: "#fff",
      padding: "2px 10px",
      fontSize: "13px",
      fontWeight: 600,
      textTransform: "capitalize",
      minWidth: "100px",
      borderRadius: 0,
    },
    "& tbody": {
      "& tr": {
        "&:hover": {
          backgroundColor: "#2f2f2f",
        },
      },
    },
    "& tr": {
      height: "auto",
      // "&:hover": {
      //   backgroundColor: "#2f2f2f",
      // },
      "& th": {
        color: "#fff",
        // padding: 0 10px,
        fontSize: "16px",
        fontWeight: 800,
      },
      "& td": {
        color: "#FCFCFC",
        borderBottom: "none",
        padding: "10px 24px 10px 16px",
        fontSize: "14px",
      },
    },
  },
  pagination: {
    color: "#fff",
  },
}));
export default function Stake(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Box>
      <Box className={classes.root}>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Amount</TableCell>
                <TableCell align="left">
                  <select>
                    <option>All</option>
                    <option>Staked </option>
                    <option>Unstaked</option>
                    <option>Withdrawn </option>
                    <option>Added</option>
                    <option>Rewards</option>
                  </select>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell scope="row">{row.srNumber}</TableCell>
                  <TableCell align="left">{row.date}</TableCell>
                  <TableCell align="left">{row.amount}</TableCell>
                  <TableCell align="left">
                    {row.stake ? (
                      <Button style={{ backgroundColor: "#06A503" }}>
                        Stake
                      </Button>
                    ) : (
                      ""
                    )}
                    {row.unstaked ? (
                      <Button style={{ backgroundColor: "#F90D0D" }}>
                        Unstaked
                      </Button>
                    ) : (
                      ""
                    )}
                    {row.withdrawn ? (
                      <Button style={{ backgroundColor: "#FF6262" }}>
                        Withdrawn
                      </Button>
                    ) : (
                      ""
                    )}
                    {row.rewards ? (
                      <Button style={{ backgroundColor: "#06A503" }}>
                        Rewards{" "}
                      </Button>
                    ) : (
                      ""
                    )}
                    {row.added ? (
                      <Button style={{ backgroundColor: "#5C834A" }}>
                        Added
                      </Button>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Box>
    </Box>
  );
}
