import React from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  Container,
  makeStyles,
} from "@material-ui/core";
import Scroll from "react-scroll";
const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  textbox: {
    position: "relative",
    zIndex: "2",
    padding: "80px 0 80px",
    "& button": {
      // "&:last-child": {
      //   marginLeft: "20px",
      //   [theme.breakpoints.down("xs")]: {
      //     marginLeft: "0px",
      //   },
      // },
    },
    "& h1": {
      fontSize: "45px",
      fontWeight: "700",
      lineHeight: "60px",
      display: "inline-block",
      background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      [theme.breakpoints.down("lg")]: {
        fontSize: "45px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        lineHeight: "40px",
      },
    },
    "& p": {
      fontSize: "18px",
      marginTop: "20px",
      color: "#fff ",
      lineHeight: "24px",
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },
  },
}));
export default function Stake(props) {
  const classes = useStyles();
  return (
    <Box className={classes.textbox}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={7}>
          <Typography variant="h1">
            Simplest way to earn passive income through staking
          </Typography>
          <Typography variant="body2">
            Token stakers will get staking rewards as per their staked DADDY
            tokens. 33% of the total revenue will be shared among the token
            stakers.
          </Typography>
          <Box mt={3}>
            <ScrollLink smooth={true} duration={500} to="section1">
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{
                  marginRight: 10,
                  marginBottom: 20,
                }}
                //   to="/app/pools-dashboard"
                //   component={Link}
              >
                Stake
              </Button>
            </ScrollLink>

            <Button
              variant="contained"
              color="secondary"
              size="large"
              // startIcon={<Telegram />}
              style={{ marginBottom: 20 }}
              onClick={() => {
                window.open("https://snowtrace.io/");
              }}
            >
              Smart Contract
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <img
            src="images/staking.png"
            alt="staking"
            style={{ maxWidth: "100%" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
