import React from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  Container,
  TextField,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import { BiCopyAlt } from "react-icons/bi";
import RevenueCard from "./RevenueCard";
import Index from "./StakingTab/Index";
import TierIndex from "./TierTab/TierIndex";
import RewardsIndex from "./RewardsTab/RewardsIndex";
const useStyles = makeStyles((theme) => ({
  Boxsection: {
    paddingTop: "110px",
  },
  mainBox: {
    padding: "20px",
    background:
      " linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
    backdropFilter: "blur(42px)",
    borderRadius: "16px",
    // margin: "80px 0 0",
    "& h5": {
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "26px",
      color: "#fff",
    },
  },
  Boxmain: {
    padding: "20px",
    background:
      " linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
    backdropFilter: "blur(42px)",
    borderRadius: "16px",
    height: "100%",
  },
  textButton: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "2px",
      fontSize: "13px",
    },

    "& button": {
      borderRadius: "4px",
      color: "#fff",
      padding: "7px 14px",
      fontSize: "12px",
      fontWeight: 700,
      border: " 2px solid transparent",
      minWidth: "53px",
      background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
      "&:hover": {
        backgroundColor: "transparent",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        color: "#fff",
        transition: "0.5s",
        boxSizing: " border-box",
        border: " 2px solid transparent",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        backgroundImage:
          "linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)",
      },
    },
  },
  refftext: {
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "23px",
    color: "#fff",
    marginBottom: "6px",
  },
  buttonright: {
    background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
    fontSize: "12px ",
    border: "1px solid transparent",
    fontWeight: 600,
    padding: " 9.5px 14px",
    minWidth: "150px",
    color: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: "none ",
    cursor: "pointer",
    border: " 2px solid transparent",
    [theme.breakpoints.down("xs")]: {
      height: "30 ",
      minWidth: "50px ",
    },
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      color: "#fff",
      transition: "0.5s",
      boxSizing: " border-box",
      border: " 2px solid transparent",
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      backgroundImage:
        "linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)",
    },
    "&:disabled": {
      boxShadow: "none",
      background: "rgb(163 200 186 / 35%)",
      color: "#313030",
    },
  },
}));
const RevenueData = [
  {
    number: "$474.00",
    heding: "Total Revenue",
  },
  {
    number: "15.28%",
    heding: "Total APR",
  },
];
export default function Stake(props) {
  const classes = useStyles();
  return (
    <Box className={classes.Boxsection}>
      <Box className={classes.mainBox}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h5">
              Stake DADDY and Earn More DADDY as Rewards
            </Typography>
          </Grid>
          {RevenueData.map((data, i) => {
            return (
              <Grid item xs={6} sm={3} md={3} key={i} className="walletSet">
                <RevenueCard data={data} type="card" index={i} />
              </Grid>
            );
          })}
        </Grid>

        <Box mt={5}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                align="left"
                className={classes.refftext}
              >
                My personal referral code
              </Typography>
              <TextField
                variant="outlined"
                placeholder="0x166132C058bA83e23AA272970DECbcbF4eA12936"
                size="large"
                fullWidth
                className={classes.textButton}
                type="text"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button style={{ fontSize: "20px" }}>
                        <BiCopyAlt />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                align="left"
                className={classes.refftext}
              >
                My personal referral URL
              </Typography>
              <TextField
                variant="outlined"
                placeholder="sale.dexdaddy.com/#/app/dashboard?0x166132C058bA83e23AA27"
                size="large"
                fullWidth
                className={classes.textButton}
                type="text"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button style={{ fontSize: "20px" }}>
                        <BiCopyAlt />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={5} className={classes.Boxmain}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} sm={4}>
              <Typography
                variant="body2"
                align="left"
                className={classes.refftext}
              >
                Total Wallet Balance : 2000
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Enter Amount"
                size="large"
                fullWidth
                className={classes.textButton}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button>Add Daddy</Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} align="center">
              <Button
                className={classes.buttonright}
                variant="contained"
                size="large"
                style={{ color: "#fff !important" }}
              >
                <img
                  src="images/daddy.svg"
                  alt="icon"
                  style={{ width: "20px", marginRight: "10px" }}
                />
                Buy Daddy
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="body2"
                align="left"
                className={classes.refftext}
              >
                Total Account Balance : 17539
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Enter Amount"
                size="large"
                fullWidth
                className={classes.textButton}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button>Withdraw</Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={5}>
              <Box className={classes.Boxmain}>
                <Index />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Box className={classes.Boxmain}>
                <RewardsIndex />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={classes.Boxmain} mt={5}>
        <TierIndex />
      </Box>
    </Box>
  );
}
