const baseurl = "https://node-blockchain.mobiloitte.com";
const user_stacking = `${baseurl}/api/v1/user_stacking`;
const user = `${baseurl}/api/v1/user`;

export const apiConfig = {
  connectWallet: `${user_stacking}/connectWallet`,
  addUserWalletAmount: `${user_stacking}/addUserWalletAmount`,
  stacking: `${user_stacking}/stacking`,
  commisionList: `${user_stacking}/commisionList`,
  tree: `${user_stacking}/tree`,
  upLineTree: `${user_stacking}/upLineTree`,
  withdrawAmount: `${user_stacking}/withdrawAmount`,
  unstakeClaim: `${user_stacking}/unstakeClaim`,
  getProfile: `${user_stacking}/getProfile`,
  stackingList: `${user_stacking}/stackingList`,

  profile: `${user}/profile`,
};
