import tinycolor from "tinycolor2";

const primary = "#42E8E0";
const secondary = "#FF5C93";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";

const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#FFF",
      hint: "#B9B9B9",
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        color: "#fff",
        backgroundColor: "#000000",
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: "#fff",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: " 0px 14px",
        height: "46px",
      },
      root: {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#42e8e0",
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#42e8e0",
          },
        },
        borderradius: 0,

        color: "#fff",
        "&:focus-visible": {
          outlineColor: "#F8B900",
        },
        "&::placeholder": {
          color: "#fff",
        },
      },
    },
    MuiChip: {
      colorPrimary: {
        backgroundColor: "#1b7e30",
      },
    },
    PrivateValueLabel: {
      label: {
        color: "#000",
      },
      circle: {
        display: "flex",
      },
    },
    MuiSlider: {
      root: {
        color: " #ffffff",
        height: "10px",
      },
      track: {
        height: "20px",
        borderRadius: "50px!important",
        backgroundColor: "#F8B900 !important",
      },
      rail: {
        height: "12px",
        borderRadius: "50px!important",
        backgroundColor: "#e21ae7 !important",
        backgroundColor: "#3A3A3A",
        opacity: 1,
      },
      thumb: {
        width: "22px",
        height: "22px",
        borderRadius: "20px!important",
        backgroundColor: "#43E3E0",
      },
      valueLabel: {
        left: "calc(-50% - -3px)",
      },
      mark: {
        display: "none",
      },
    },
    MuiMenuItem: {
      root: { color: "#fff", padding: "6px" },
    },

    MuiListItemText: {
      root: {
        margin: "0 !important",
        "& span": {
          "@media (max-width:991px)": {
            fontSize: "14px !important",
          },
        },
      },
    },

    MuiPaper: {
      root: { color: "#fff" },
      rounded: {
        background: "linear-gradient( 152.97deg, #515151 0%, #434343 100%)",
        // backgroundColor: " #000",
        borderRadius: "10px",
        backdropFilter: "blur(42px)",
      },

      elevation8: {
        background: "#000 !important",
      },
    },
    Styled: {
      contained: {
        backgroundColor: "#536dfe00",
        border: "1.5px solid #F8B900",
        borderRadius: "50px",
        fontSize: "14px",
        fontWeight: 600,
        minWidth: "150px",
        height: "40px",
        "&:hover": {
          background: "linear-gradient( 180deg, #8F5409 0%, #F8B900 100%)",
        },
        "&.active": {
          background: "linear-gradient( 180deg, #8F5409 0%, #F8B900 100%)",
        },
      },
    },
    MuiDrawer: {
      paper: {
        overflow: "inherit",
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "#43d8d1",
        // padding: "12px",
        // overflow: "visible",
        // fontSize: "1.5rem",
        // textAlign: "center",
        // transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        // borderRadius: "50%",
      },
    },
    MuiFilledInput: {
      underline: {
        "&::after": {
          left: "0",
          right: "0",
          bottom: "0",
          content: " ",
          position: "absolute",
          transform: "scaleX(0)",
          transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          borderBottom: "2px solid #42e8e0",
          pointerEvents: "none",
        },
      },
      underline: {
        "&::before": {
          display: "none",
        },
      },
      input: {
        padding: "15px 12px 15px",
        "&::placeholder": {
          color: "#fff",
        },
      },
      root: {
        backgroundColor: "#3E3E3E",
        color: "#6A6A6A",
        borderRadius: "10px !important",
      },
    },
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor:
            "linear-gradient(180deg, rgba(248,185,0,1) 16%, rgba(143,84,9,1) 79%)",
        },
      },
      contained: {
        "&:hover": {
          backgroundColor: "linear-gradient(180deg, #F8B900 0%, #8F5409 100%)",
        },
      },
      containedPrimary: {
        borderRadius: "50px",
        fontSize: "14px",
        fontWeight: 600,
        minWidth: "150px",
        height: "50px",
        backgroundColor: "transparent",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        color: "#fff",
        transition: "0.5s",
        boxSizing: " border-box",
        border: " 2px solid transparent",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        backgroundImage:
          "linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)",
        "&:hover": {
          background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
          backgroundColor: "#000000",
        },
        "&.active": {
          background: "linear-gradient( 180deg, #8F5409 0%, #F8B900 100%)",
        },
      },
      containedSecondary: {
        backgroundColor: "transparent",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        color: "#fff",
        borderRadius: "50px",
        fontSize: "14px",
        fontWeight: 600,
        minWidth: "150px",
        height: "50px",
        boxSizing: " border-box",
        border: " 2px solid transparent",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        backgroundImage:
          "linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)",
        "&:hover": {
          background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
        },
        "&.active": {
          background: "linear-gradient( 180deg, #8F5409 0%, #F8B900 100%)",
        },
      },
      root: {
        fontFamily: '"Poppins", sans-serif !important',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: "none",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "skyblue",
          "&:focus": {
            backgroundColor: "skyblue",
          },
        },
      },
      "&button": {
        "&:hover": {
          backgroundColor: "rgb(70 70 70 / 39%)",
          color: "#000",
        },
        "&:focus": {
          backgroundColor: "rgb(70 70 70 / 39%)",
          color: "#000",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, .5)",
        paddingLeft: 24,
      },
      head: {
        fontSize: "0.95rem",
      },
      body: {
        fontSize: "0.95rem",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10,
      },
    },
  },
};

export default defaultTheme;
