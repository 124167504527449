import React, { useState } from "react";
import { Box, Container, makeStyles, Button, Grid } from "@material-ui/core";
// import Page from "src/components/Page";
import UnstakeReferral from "./UnstakeReferral";
import StakeRewards from "./StakeRewards";
const useStyles = makeStyles((theme) => ({
  walletPage: {
    position: "relative",
    zIndex: "9",
    "& div": {
      "@media (max-width:767px)": {
        display: "flex",
      },
    },
    "& button": {
      fontSize: "16px",
      color: "#a4a1a1",
      textTransform: "capitalize",
      borderBottom: "2px solid transparent",
      borderRadius: 0,
      paddingTop: 0,
      "&:hover": {
        color: "#fff !important",
        borderColor: "#fff",
      },
      "&.active": {
        color: "#fff !important",
        borderColor: "#fff",
      },
    },
  },
}));
function Index(props) {
  const classes = useStyles();
  const [tabview, setTabView] = useState("StakeRewards");
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.Padding_Top}>
        <Box className={classes.walletPage} mb={2}>
          <Box>
            <Button
              className={tabview === "StakeRewards" ? "active" : " "}
              onClick={() => setTabView("StakeRewards")}
            >
              My Staking Rewards
            </Button>
            <Button
              className={tabview === "UnstakeReferral" ? "active" : " "}
              onClick={() => setTabView("UnstakeReferral")}
            >
              UnstakeMy Referral Details
            </Button>
          </Box>
        </Box>

        <Grid item xs={12} md={12} lg={12}>
          <Box className="TabButtonsContant">
            {tabview === "StakeRewards" ? <StakeRewards /> : ""}
            {tabview === "UnstakeReferral" ? <UnstakeReferral /> : ""}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default Index;
