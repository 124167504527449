import React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  revenueBox: {
    border: "1px solid rgba(255, 255, 255, 0.58)",
    height: "100%",
    padding: "0px 16px",
    background:
      "linear-gradient(152.97deg, rgba(0, 0, 0, 0) 0%, rgba(44, 53, 53, 0.335) 0.01%, rgba(66, 232, 224, 0.14) 100%)",
    borderRadius: "10px",
    backdropFilter: "blur(42px)",
    padding: "20px 10px",
    color: "#fff",
    textAlign: "center",
    transition: " 0.3s",
    "&:hover": {
      transform: "translateY(-10px)",
    },
    "& h5": {
      color: "#fff",
      fontSize: "16px !important",
      fontWeight: "700 !important",
      lineHeight: "25px !important",
      wordBreak: "break-word",
    },

    "& p": {
      fontSize: "13px",
      color: "#a6a6a6",
    },
  },
  Tier: {
    border: "1px solid rgba(255, 255, 255, 0.58)",
    height: "100%",
    padding: "0px 16px",
    background:
      "linear-gradient(152.97deg, rgba(0, 0, 0, 0) 0%, rgba(44, 53, 53, 0.335) 0.01%, rgba(66, 232, 224, 0.14) 100%)",
    borderRadius: "10px",
    backdropFilter: "blur(42px)",
    padding: "20px 10px",
    color: "#fff",
    textAlign: "center",
    transition: " 0.3s",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
      transform: "translateY(-10px)",
    },
    "& h5": {
      color: "#fff",
      fontSize: "16px !important",
      fontWeight: "700 !important",
      lineHeight: "25px !important",
      wordBreak: "break-word",
    },
    "& select": {
      height: "30px",
      minWidth: "50px",
      borderRadius: "2px",
      border: "1px solid #444A5F",
      background:
        "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
      color: "#fff",
      "&:focus": {
        outline: "none",
      },
    },
  },
  note: {
    fontSize: "9px",
    color: "#fff",
  },
}));

export default function Stake(props) {
  const { data, type } = props;
  console.log("data", data);
  const classes = useStyles();
  return (
    <>
      {data.dummy ? (
        <>
          <Box className={classes.Tier}>
            <Typography variant="h5">Tier</Typography>
            <select>
              <option>All</option>
              <option>-3 </option>
              <option>-2</option>
              <option>-1 </option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </Box>
          <span className={classes.note}>
            Note : You can select tiers from dropdown
          </span>
        </>
      ) : (
        <Box className={classes.revenueBox}>
          <Typography variant="h5"> {data.number} </Typography>
          <Typography variant="body"> {data.heding} </Typography>
        </Box>
      )}
    </>
  );
}
