import React from "react";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import Banner from "./Banner";
import Revenue from "./Revenue";
import StakeBox from "./StakeBox";
export default function Stake(props) {
  return (
    <Box>
      <Banner />
      <Revenue />
      <div id="section1">
        <StakeBox />
      </div>
    </Box>
  );
}
