import React from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  Container,
  TextField,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({}));
export default function Stake(props) {
  const classes = useStyles();
  return (
    <Box className="treeBox">
      <div id="tree">
        <div className="branch">
          <div className="entry">
            <span style={{ backgroundColor: "#c4dc41" }}>[Text]</span>
            <div className="branch">
              <div className="entry">
                <span style={{ backgroundColor: "rgb(240 150 15)" }}>
                  [Text]
                </span>
                <div className="branch">
                  <div className="entry">
                    <span style={{ backgroundColor: "rgb(255 107 74)" }}>
                      [Text]
                    </span>
                    <div className="branch">
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="entry">
                    <span style={{ backgroundColor: "rgb(255 107 74)" }}>
                      [Text]
                    </span>
                    <div className="branch">
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="entry">
                <span style={{ backgroundColor: "rgb(240 150 15)" }}>
                  [Text]
                </span>
                <div className="branch">
                  <div className="entry">
                    <span style={{ backgroundColor: "rgb(255 107 74)" }}>
                      [Text]
                    </span>
                    <div className="branch">
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="entry">
                    <span style={{ backgroundColor: "rgb(255 107 74)" }}>
                      [Text]
                    </span>
                    <div className="branch">
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="entry">
            <span style={{ backgroundColor: "#c4dc41" }}>[Text]</span>
            <div className="branch">
              <div className="entry">
                <span style={{ backgroundColor: "rgb(240 150 15)" }}>
                  [Text]
                </span>
                <div className="branch">
                  <div className="entry">
                    <span style={{ backgroundColor: "rgb(255 107 74)" }}>
                      [Text]
                    </span>
                    <div className="branch">
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="entry">
                    <span style={{ backgroundColor: "rgb(255 107 74)" }}>
                      [Text]
                    </span>
                    <div className="branch">
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="entry">
                <span style={{ backgroundColor: "rgb(240 150 15)" }}>
                  [Text]
                </span>
                <div className="branch">
                  <div className="entry">
                    <span style={{ backgroundColor: "rgb(255 107 74)" }}>
                      [Text]
                    </span>
                    <div className="branch">
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="entry">
                    <span style={{ backgroundColor: "rgb(255 107 74)" }}>
                      [Text]
                    </span>
                    <div className="branch">
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                      <div className="entry">
                        <span style={{ backgroundColor: "rgb(49 186 239)" }}>
                          [Text]
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
