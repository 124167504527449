import React, { useState } from "react";
import { Box, Container, makeStyles, Button, Grid } from "@material-ui/core";
// import Page from "src/components/Page";
import Unstake from "./Unstake";
import Stake from "./Stake";
import Reward from "./Reward";
const useStyles = makeStyles((theme) => ({
  walletPage: {
    position: "relative",
    zIndex: "9",
    "& button": {
      fontSize: "16px",
      color: "#a4a1a1",
      textTransform: "capitalize",
      borderBottom: "2px solid transparent",
      borderRadius: 0,
      paddingTop: 0,
      "&:hover": {
        color: "#fff !important",
        borderColor: "#fff",
      },
      "&.active": {
        color: "#fff !important",
        borderColor: "#fff",
      },
    },
  },
}));
function Index(props) {
  const classes = useStyles();
  const [tabview, setTabView] = useState("Stake");
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.Padding_Top}>
        <Box className={classes.walletPage} mb={2}>
          <Box>
            <Button
              className={tabview === "Stake" ? "active" : " "}
              onClick={() => setTabView("Stake")}
            >
              Stake
            </Button>
            <Button
              className={tabview === "Unstake" ? "active" : " "}
              onClick={() => setTabView("Unstake")}
            >
              Unstake
            </Button>
            <Button
              className={tabview === "Reward" ? "active" : " "}
              onClick={() => setTabView("Reward")}
            >
              Reward Claim
            </Button>
          </Box>
        </Box>

        <Grid item xs={12} md={12} lg={12}>
          <Box className="TabButtonsContant">
            {tabview === "Stake" ? <Stake /> : ""}
            {tabview === "Unstake" ? <Unstake /> : ""}
            {tabview === "Reward" ? <Reward /> : ""}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default Index;
