export const NetworkContextName = "Avalanche Network";
export const ACTIVE_NETWORK = 43113; //43114;
export const Finalcontractaddress =
  "0x663788a7B39Ad13fcA8F3AaB8f7ab5E2d57f106A";
export const RPC_URL = "https://api.avax-test.network/ext/bc/C/rpc"; // "https://api.avax.network/ext/bc/C/rpc";
export const networkURL = "https://snowtrace.io/address/"; //'https://snowtrace.io/address/

export const tetherToken = "0x28263cAAed18e6C7Cab2969D4CB64BAb74300329";
export const daddyToken = "0x86E5Dbbb5F3fE724D7a14e17D48F93E5eF8F78f4";
export const seedSaleDistribution =
  "0xD2F857255B03bC3AdbBe2d4b5c8F035D6D07fe74";

export const seedSaleVesting = "0xDE4C95d16E9Ff3d5c3c82dB141c81CfAe2749F0e";
export const privateSaleVesting = "0x1c36dcf943c557e37FA87b54628Ba785EA003911";
export const preSaleVesting = "0x7997634C0BD0C067e0C85a315E00a2f7ea1BAD8d";

export const deadAddress = "0x0000000000000000000000000000000000000000";
export const approveAmount =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";

export const seedSaleStartTimeStamp = 1642048200;
export const seedSalePrice = 0.1;
export const saleTimeDiffInMinute = 60;

export const adminWalletAddress = "0xA93647C91133454fB265821334083375b12F06e5";
export const NetworkDetails = [
  {
    chainId: "0xA86A",
    chainName: "Avalanche Network",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io/"],
  },
];
