import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { BinanceConnector } from "@bscswap/binance-connector";
import { RPC_URL } from "../constants";

const NETWORK_URL = RPC_URL; // process.env.REACT_APP_NETWORK_URL

export const injected = new InjectedConnector({
  supportedChainIds: [43113, 5, 42, 56, 97, 1287, 361, 365, 43114],
});

export const mathWalletInjected = new InjectedConnector({
  supportedChainIds: [43113, 5, 42, 56, 97, 1287, 361, 365, 43114],
});

export const walletconnect = new WalletConnectConnector({
  rpc: {
    43114: NETWORK_URL,
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 15000,
});

export const binanceinjected = new BinanceConnector({
  supportedChainIds: [43113, 5, 42, 56, 97, 1287, 361, 365, 43114],
});

export const SUPPORTED_WALLETS = [
  {
    name: "METAMASK",
    data: {
      connector: injected,
      name: "MetaMask",
      iconName: "/images/walletImages/metamask-fox.svg",
      description: "Easy-to-use browser extension.",
      href: null,
      color: "#E8831D",
    },
  },
  // {
  //   name: 'BINANCE',
  //   data: {
  //     connector: binanceinjected,
  //     name: 'Binance Chain',
  //     iconName: '/images/walletImages/bcw.svg',
  //     description: 'A Crypto Wallet for Binance Smart Chain',
  //     href: null,
  //     color: '#F9A825',
  //   },
  // },
  {
    name: "TRUSTWALLET",
    data: {
      connector: injected,
      name: "Trust Wallet",
      iconName: "/images/walletImages/trust.svg",
      description: "The most trusted & secure crypto wallet",
      href: null,
      color: "#3375BB",
    },
  },
  {
    name: "MATHWALLET",
    data: {
      connector: mathWalletInjected,
      name: "Math Wallet",
      iconName: "/images/walletImages/math.png",
      description: "Your Gateway to the World of Blockchain",
      href: null,
      color: "#000000",
    },
  },
  {
    name: "WALLETCONNECT",
    data: {
      connector: walletconnect,
      name: "Wallet Connect",
      iconName: "/images/walletImages/walletConnect.png",
      description: "Your Gateway to the World of Blockchain",
      href: null,
      color: "#000000",
    },
  },
  // {
  //   name: 'TORUS',
  //   data: {
  //     connector: torus,
  //     name: 'TORUS',
  //     iconName: '/images/walletImages/torus.png',
  //     description: 'Your Gateway to the World of Blockchain',
  //     href: null,
  //     color: '#000000',
  //   },
  // },
];
