import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import useStyles from "./styles";

export default function Header() {
  var classes = useStyles();
  const location = useLocation();
  return (
    <Box className={classes.mainmenus}>
      <Typography variant="body2" component="span" className={classes.navLinks}>
        <img src="images/audited.svg" />
      </Typography>
    </Box>
  );
}
