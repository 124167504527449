import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { SUPPORTED_WALLETS } from '../../connectors';
import { UserContext } from '../../context/User';

export default function ConnectWallet({ open, handleClose }) {
  const user = useContext(UserContext);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box textAlign='center' mt={2} mb={2}>
        <Typography variant='h3'>Connect wallet</Typography>
      </Box>
      <DialogContent>
        <Box>
          <Grid container spacing={1}>
            {SUPPORTED_WALLETS.map((item, i) => {
              return (
                <Grid xm={12} sm={6} key={i}>
                  <Box
                    style={{ cursor: 'pointer' }}
                    m={2}
                    onClick={() => {
                      window.sessionStorage.removeItem('walletName');
                      window.sessionStorage.setItem('walletName', item.name);
                      user.connectWallet(item.data);
                    }}
                    textAlign='center'
                  >
                    <Box textAlign='center'>
                      <img
                        src={item.data.iconName}
                        alt={''}
                        width='25'
                        height='25'
                        style={{ marginRight: 0 }}
                        // className="mr-3 mt-1"
                      />
                    </Box>
                    <Typography>{item.data.name}</Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </DialogContent>
      <hr />
      <Box textAlign='center' mt={2} mb={2}>
        <Button onClick={handleClose} variant='contained' color='primary'>
          Close
        </Button>
      </Box>
    </Dialog>
  );
}
