import React from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  Container,
  makeStyles,
} from "@material-ui/core";
import RevenueCard from "../RevenueCard";
const useStyles = makeStyles((theme) => ({}));
const RevenueData = [
  {
    number: "$474.07",
    heding: "Claimed Commission",
  },
  {
    number: "$4746.00",
    heding: "Claimable Commission",
  },
  {
    dummy: "Tier",
  },
  {
    number: "4746",
    heding: "Total Community",
  },
  {
    number: "$4746.50",
    heding: "24hrs Commission",
  },
  {
    number: "$4746.86",
    heding: "Total Commission",
  },
  {
    number: "$4746.06",
    heding: "Tiers Existing Stake",
  },
];
export default function Stake(props) {
  const classes = useStyles();
  return (
    <Box className={classes.textbox}>
      <Grid container spacing={2} alignItems="flex-start">
        {RevenueData.map((data, i) => {
          return (
            <Grid item xs={6} sm={4} md={6} lg={4} key={i}>
              <RevenueCard data={data} index={i} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
