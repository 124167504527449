import React, { createContext, useEffect, useState } from "react";
import { SUPPORTED_WALLETS } from "../connectors";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK, NetworkDetails } from "../constants/";
import { toast } from "react-toastify";
import { apiConfig } from "../config/apiConfig";
import axios from "axios";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, active, account, library, chainId } = useWeb3React();
  const [nftList, setNftList] = useState([]);
  const [userTotalNFTS, setUserTotalNFTS] = useState(0);
  const [isSaleActive, setIsSaleActive] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [userData, setUserData] = useState();

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  const connectWalletWeb3 = (data) => {
    const connector = data.connector;

    if (connector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined;
    }

    activate(connector, undefined, true).catch((error) => {
      if (error) {
        toast.error(JSON.stringify(error.message));
        activate(connector);
      }
    });
  };

  const connectWalletAPICall = async () => {
    try {
      setIsLogin(false);
      const res = await axios.post(apiConfig.connectWallet, {
        walletAddress: account,
      });
      if (res.data.statusCode === 200) {
        setTokenSession(res.data.result.token);
        setIsLogin(true);
        // setUserData(res.data.result);
      } else {
        toast.error(res.data.responseMessage);
        setTokenSession();
      }
    } catch (error) {
      setTokenSession();
      console.log("ERROR", error);
      if (error.response) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    if (account) {
      connectWalletAPICall();
    } else {
      setIsLogin(false);
      setTokenSession(null);
    }
  }, [account]);

  const getUserProfile = async () => {
    try {
      const res = await axios.get(apiConfig.getProfile, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      if (res.data.statusCode === 200) {
        setUserData(res.data.result);
      } else {
        setIsLogin(false);
        setUserData();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (isLogin) {
      getUserProfile();
    } else {
      setIsLogin(false);
    }
  }, [isLogin]);

  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
    }
  };

  let data = {
    nftList,
    userTotalNFTS,
    isSaleActive,
    userData,
    isLogin,
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: (data) => connectWalletWeb3(data),
    getUserProfile: () => getUserProfile(),
  };

  // useEffect(() => {
  //   const userAddress = window.sessionStorage.getItem('userAddress');
  //   if (userAddress) {
  //     data.connectWallet();
  //   }
  // }, []); //eslint-disable-line

  useEffect(() => {
    if (window.sessionStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.sessionStorage.getItem("walletName")
      );
      connectWalletWeb3(selectectWalletDetails[0].data);
    }
  }, []);

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
